import React, { Component } from "react";

class Title extends Component {
  render() {
    return (
      <div className="title">
        <h1>Clinic Checker</h1>
        <h2>
          Paste the clinic's site URL below to check if it's a legitimate
          clinic.
        </h2>
      </div>
    );
  }
}

export default Title;

import React, { Component } from "react";
import logo from "./images/logo.png";
import Loading from './Loading'

class Form extends Component {
  state = {
    enteredUrl: "",
    searchableUrl: "",
    realClinicFound: false,
    realClinicInfo: "",
    fakeClinicFound: false,
    fakeClinicInfo: "",
    noResultsFound: false,
    loading: false,
  };

  handleChange = (e) => {
    this.setState({ enteredUrl: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      searchableUrl: "",
      realClinicFound: false,
      realClinicInfo: "",
      fakeClinicFound: false,
      fakeClinicInfo: "",
      noResultsFound: false,
      loading: true
    });
    this.props.realClinicInfo("");
    this.props.fakeClinicInfo("");
    this.props.showUnknownClinic(false);
    this.cleanUrl();
  };

  cleanUrl = () => {
    let enteredUrl = this.state.enteredUrl.trim();

    this.setState({ searchableUrl: enteredUrl }, () =>
      this.clinicDatabaseSearch()
    );
  };

  clinicDatabaseSearch = () => {
    let searchableUrl = this.state.searchableUrl;
    fetch("https://clinic-checker-backend.herokuapp.com/clinic", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ givenURL: searchableUrl }),
    })
      .then((resp) => resp.json())
      .then((json) =>
        this.setState({ realClinicFound: json.found }, () => {
          if (this.state.realClinicFound) {
            if (json.multipleFound === true) {
              console.log(
                "that's a real clinic, need a separate card for that"
              );
              this.setState({ loading: false, }, () => {
                this.props.realClinicInfo("multiple");
              })
            } else {
              this.setState({
                realClinicInfo: json.information.fields,
                loading: false,
              }, () =>
                this.props.realClinicInfo(this.state.realClinicInfo)
              );
            }
          } else {
            this.fakeDatabaseSearch();
          }
        })
      );
  };

  fakeDatabaseSearch = () => {
    let searchableUrl = this.state.searchableUrl;
    fetch("https://clinic-checker-backend.herokuapp.com/fake", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ givenURL: searchableUrl }),
    })
      .then((resp) => resp.json())
      .then((json) =>
        this.setState({ fakeClinicFound: json.found }, () => {
          if (this.state.fakeClinicFound) {
            this.setState({ loading: false, fakeClinicInfo: json.information }, () =>
              this.props.fakeClinicInfo(this.state.fakeClinicInfo)
            );
          } else {
            this.setState({ loading: false, noResultsFound: true }, () =>
              this.props.showUnknownClinic(true)
            );
          }
        })
      );
  };

  render() {
    return (
      <>
        <form className="form">
          <div>
            <label>
              <input
                className="formInput"
                type="text"
                name="url"
                placeholder="http://"
                value={this.state.url}
                onChange={this.handleChange}
              />
            </label>
          </div>
          <div>
            <button type="submit" value="Check" onClick={this.handleSubmit}>
              {
                this.state.loading ? (
                  <Loading />
                ) : (<p>Check</p>)
              }
            </button>
          </div>
        </form>
        <div id="poweredBy">
          <p>
            powered by
            <a
              href="https://ineedana.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={logo}
                alt="ineedana.com logo"
                style={{
                  width: "100px",
                }}
              />{" "}
            </a>
          </p>
        </div>
      </>
    );
  }
}

export default Form;

import React, { Component } from "react";
import thumbsUp from "./images/thumbs-up.png";
import trustedIcon from "./images/trusted-icon.png";

class KnownClinic extends Component {
  render() {
    return (
      <div id="knownClinicResult">
        <div id="knownClinicAlert">
          <h4>
            <img
              src={thumbsUp}
              alt="Thumbs up"
              style={{ width: "16px", paddingRight: "2%" }}
            />{" "}
            This is a legitimate clinic!
          </h4>
        </div>
        {this.props.information === "multiple" ? null : (
          <div id="knownClinicCard">
            <img
              src={trustedIcon}
              alt="Green icon with check mark that says 'Verified'"
              id="realClinicIcon"
            />
            <h3>{this.props.information.name}</h3>
            <h4>
              {this.props.information.streetaddress},{" "}
              {this.props.information.city}, {this.props.information.state}{" "}
              {this.props.information.zipcode}
            </h4>
            {/* <div id="phoneAndWebsite"> */}
            <h5>{this.props.information.phone}</h5>
            <h5>
              <a
                href={"http://" + this.props.information.website}
                rel="noopener noreferrer"
                target="_blank"
              >
                Visit Their Website
              </a>
            </h5>
            {/* </div> */}
          </div>
        )}
      </div>
    );
  }
}

export default KnownClinic;

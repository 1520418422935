import React, { Component } from "react";

class InfoFooter extends Component {
  render() {
    return (
      <div className="footerBackground">
        <div className="footerInformation">
          <div>
            <h3>Why it's important to check your clinic</h3>
          </div>
          <div>
            <p>
              Deciding to have an abortion can sometimes be intimidating and complicated.
              You deserve to get unbiased and honest help. Unfortunately, there
              are many organizations, known as “crisis pregnancy centers” that
              pretend to be abortion or options counseling clinics with the sole
              purpose of talking you out of having an abortion.
              <br />
              <br />
              In many states, there are more crisis pregnancy centers than
              abortion clinics. You probably have one near you. They will
              frequently buy Google ads so that they appear above actual
              abortion clinics when you’re searching. This is why it’s important
              to verify that the clinic is real. We've done that for you!
            </p>
          </div>
          <div>
            <br />
            <hr />
            <br />
            <div style={{ textAlign: "center" }}>
              <span id="pullQuote">
                Every clinic on{" "}
                <a
                  href="https://ineedana.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ineedana.com
                </a>{" "}
                has been verified.
              </span>
            </div>
            <br />
            <hr />
          </div>
          <div>
            <p>
              Fake clinics will often advertise free ultrasounds (you may see signs on
              your way to the clinic that say “Pregnant? Free ultrasound!") and
              post-pregnancy support (diapers, financial, etc.) to try to talk
              you out of having an abortion. 
              <br />
              <br />
              All of the fake clinics we show here come from <a href='https://reproaction.org/fakeclinicdatabase/' target='_blank'>ReproAction's fake clinic database</a>
              <br />
              <br />
              Many of these fake clinics are financed by state tax dollars but
              are not regulated by any medical institutions. To learn more about
              fake clinics check out{" "}
              <a
                href="https://www.exposefakeclinics.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.exposefakeclinics.com
              </a>{" "}
              
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default InfoFooter;

import React, { Component } from "react";

class UnknownClinic extends Component {
  render() {
    return (
      <div id="unknownClinicResult">
        <div id="unknownClinicAlert">
          <h4>This listing isn’t in our database.</h4>
          <h5>
            Help us by informing us of unlisted clinics (or reporting fake ones)
            in the form below, or by emailing us at info@ineedana.com
          </h5>
        </div>
        <div id="unknownClinicForm">
          <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
          <iframe
            className="airtable-embed airtable-dynamic-height"
            src="https://airtable.com/embed/shrwfxJtenSERrKE0?backgroundColor=cyan"
            frameBorder="0"
            onmousewheel=""
            title="Unknown Clinic Form"
            width="100%"
            height="1741"
            style={{ background: "transparent", border: "1px solid #ccc" }}
          ></iframe>
        </div>
      </div>
    );
  }
}

export default UnknownClinic;

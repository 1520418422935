import React, { Component } from "react";
import "./App.css";
import Title from "./Title";
import Form from "./Form";
import InfoFooter from "./InfoFooter";
import KnownClinic from "./KnownClinic";
import KnownFake from "./KnownFake";
import UnknownClinic from "./UnknownClinic";

class Home extends Component {
  state = {
    realClinicInformation: "",
    fakeClinicInformation: "",
    showUnknownClinic: false,
  };
  render() {
    return (
      <div style={{ width: "100%", margin: "auto", height: "100%" }}>
        <div className="topofpage">
        <Title />
        <Form
          realClinicInfo={(e) => this.setState({ realClinicInformation: e })}
          fakeClinicInfo={(e) => this.setState({ fakeClinicInformation: e })}
          showUnknownClinic={(e) => this.setState({ showUnknownClinic: e })}
        />
        </div>
        <div className="bottomOfPage">
          {this.state.realClinicInformation !== "" ? (
            <KnownClinic information={this.state.realClinicInformation} />
          ) : null}
          {this.state.fakeClinicInformation !== "" ? (
            <KnownFake information={this.state.fakeClinicInformation} />
          ) : null}
          {this.state.showUnknownClinic ? <UnknownClinic /> : null}
          <InfoFooter />
        </div>
      </div>
    );
  }
}

export default Home;

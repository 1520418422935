import React, { Component } from "react";
import alertIcon from "./images/alert-icon.png";
import fakeIcon from "./images/fake-icon.png";

class KnownFake extends Component {
  render() {
    return (
      <div id="fakeClinicResult">
        <div id="fakeClinicAlert">
          <h4>
            <img
              src={alertIcon}
              alt="Alert icon"
              style={{ height: "16px", paddingRight: "2%" }}
            />{" "}
            This is a crisis pregnancy center
          </h4>
          <h5>
            This clinic is listed in ReproAction’s{" "}
            <a
              href="https://reproaction.org/a-black-midwesterners-perspective-on-abortion-access-regional-culture-and-why-people-should-care-about-the-midwest/"
              target="_blank"
              rel="noopener noreferrer"
            >
              fake clinic database
            </a>
            .
          </h5>
        </div>
        {this.props.information.length > 1 ? null : (
          <div id="fakeClinicCard">
            <img
              src={fakeIcon}
              alt="Red icon with an 'X' mark that says 'Fake'"
              id="fakeClinicIcon"
            />
            <h3>{this.props.information[0].cpcName}</h3>
            <h4>
              {this.props.information[0].address},{" "}
              {this.props.information[0].cityStateZip}
            </h4>
            {/* <h5>
              <a
                href={this.props.information[0].website}
                rel="noopener noreferrer"
                target="_blank"
              >
                Website
              </a>
            </h5> */}
          </div>
        )}
      </div>
    );
  }
}

export default KnownFake;
